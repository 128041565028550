import {
    Box,
    Button,
    Grid,
    InputBase,
    TextField,
    Typography,
  } from "@mui/material";
  import { useForm } from "hooks/useForm";
  import { useState, useEffect } from "react";
  import { postManualRegister } from "services/postManualRegister";
  import { processPlans } from "utils/processPlans";
  import { getPlans } from "../../services/getPlans";
  import { Notification } from "components/registration-notice";
  
  const initialForm = {
    nombre: "",
    apellido: "",
    correo: "",
    paymentId: "",
    amount: "",
    descripcion: "",
  };
  
  const initialSnackBar = {
    isSnackBarOpen: false,
    severity: "success",
    message: "Alumno registrado correctamente",
  };
  
  const errorSnackbar = {
    isSnackBarOpen: true,
    severity: "error",
    message: "Ha ocurrido un error",
  };
  
  export const UserRegisterBasicPlan = () => {
    const {
      nombre,
      apellido,
      correo,
      paymentId,
      amount,
      descripcion,
      onInputChange,
      formState,
      onResetForm,
    } = useForm(initialForm);
    const [snackBarInfo, setSnackBarInfo] = useState(initialSnackBar);
    const [plansList, setPlansList] = useState([]);
    const [plansRaw, setPlansRaw] = useState([]);
    const [planSelected, setPlanSelected] = useState("");
    const [renderNotice, setRenderNotice] = useState(false);
    const [email, setEmail] = useState("");
  
    const getInfo = async () => {
      const plans = await getPlans();
      setPlansRaw(plans);
      setPlanSelected(76);
    };
    useEffect(() => {
      getInfo();
    }, []);
  
  
    const onPlanSelectedChange = (e) => {
      setPlanSelected(e.target.value);
    };
  
    const onSubmit = async (e) => {
      e.preventDefault();
      if (
        nombre === "" ||
        apellido === "" ||
        correo === "" ||
        planSelected === ""
      ) {
        return setSnackBarInfo({
          ...errorSnackbar,
          message: "Por favor completa los datos",
        });
      }
      const res = await postManualRegister(
        processPlans({ ...formState, plansRaw, planSelected })
      );
      if (!res.ok) {
        return setSnackBarInfo({ ...errorSnackbar, message: res.errorMessage });
      }
      onResetForm();
      setPlanSelected();
      setEmail(formState.correo);
      setRenderNotice(true);
    };
    return (
      <>
        {renderNotice ? (
          <Notification email={email} />
        ) : (
          <Grid
            container
            minHeight={"100vh"}
            justifyContent={"center"}
            alignItems="center"
            py={10}
          >
            <Box
              component="form"
              onSubmit={onSubmit}
              sx={{
                width: { xs: "95%", sm: "90%" },
                maxWidth: "700px",
                backgroundColor: "#fff",
                borderRadius: "6px",
                p: 4,
                boxShadow: "0 0 15px rgba(0,0,0, 0.03)",
              }}
            >
              <Typography variant="h4" fontWeight={600} color={"#000"} mb={3}>
                Registrarse
              </Typography>
              <Grid item xs={12} my={1}>
                <InputBase
                  label="Nombre"
                  placeholder="Nombre"
                  variant="outlined"
                  name="nombre"
                  value={nombre}
                  onChange={onInputChange}
                  fullWidth
                  sx={inputSx}
                />
              </Grid>
              <Grid item xs={12} my={1}>
                <InputBase
                  label="Apellido"
                  variant="outlined"
                  placeholder="Apellido"
                  name="apellido"
                  value={apellido}
                  onChange={onInputChange}
                  fullWidth
                  sx={inputSx}
                />
              </Grid>
              <Grid item xs={12} my={1}>
                <InputBase
                  label="Correo"
                  type="email"
                  variant="outlined"
                  placeholder="Correo Electronico"
                  name="correo"
                  value={correo}
                  onChange={onInputChange}
                  fullWidth
                  sx={inputSx}
                />
              </Grid>
              <Grid item my={2}>
                <Button
                  variant="outlined"
                  type="submit"
                  sx={{
                    backgroundColor: "#2de678",
                    border: "none",
                    color: "#fff",
                    fontWeight: 700,
                    ":hover": {
                      backgroundColor: "#2de678",
                      border: "none",
                    },
                    borderRadius: "8px",
                  }}
                >
                  Registrar
                </Button>
              </Grid>
            </Box>
          </Grid>
        )}
      </>
    );
  };
  
  const inputSx = {
    backgroundColor: "#efefef",
    py: 0.5,
    pl: 2,
    borderRadius: "8px",
    ":focus": { outline: "1px solid #ccc" },
    my: 1,
  };
  