import { MeetingsContext } from "context/meetingsContext";
import { useContext, useEffect, useState } from "react";
import Bar from "./Bar";
import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg";
import { CoursesContext } from "context/coursesContext";


export default function DashboardInfo() {

  const [completedCourses, setCompletedCourses] = useState(0);
  const [coursesInProgress, setCoursesInProgress] = useState(0);
  const [mainCourses, setMainCourses] = useState([]);
  const [secondaryCourses, setSecondaryCourses] = useState([]);
  const [calculating, setCalculating] = useState(true);
  const user = JSON.parse(window.localStorage.getItem("loggedAppUser"));
  const username = user.nombre.split(" ").slice(0, 1);
  const { courses } = useContext(CoursesContext);
  const { appointmentsAvailable } = useContext(MeetingsContext);
  const [isSelfOpen, setIsSelfOpen] = useState(true);
  const [isPacedOpen, setIsPacedOpen] = useState(false);

  const calculateCourses = (courses) => {
    setCalculating(true);
    const main = [];
    const secondary = [];
    let inProgress = 0;
    let completed = 0;
    courses.forEach(({ nombre, porcentajeCompletado, planEstudio }) => {
      const courseData = { nombre, porcentajeCompletado };
      if (porcentajeCompletado > 0 && porcentajeCompletado < 100) {
        inProgress++;
      } else if (porcentajeCompletado === 100) {
        completed++;
      }
      if (planEstudio !== 'Obligatorio') {
        main.push(courseData);
      } else if (porcentajeCompletado > 0) {
        secondary.push(courseData);
      }
    });
    secondary.sort((a, b) => b.porcentajeCompletado - a.porcentajeCompletado);
    setCompletedCourses(completed);
    setCoursesInProgress(inProgress);
    setMainCourses(main);
    setSecondaryCourses(secondary.slice(0, 5));
    setCalculating(false);
  };

  useEffect(() => {
    if (courses.length > 0) {
      calculateCourses(courses);
    }
  }, [user.id, courses]);


  return (
    <div className="flex w-full h-fit flex-col gap-3 bg-transparent p-5 text-gray-50">
      <div className="flex flex-col">
        <span className="text-center text-xl font-semibold ">
          Hey, {username}
          <span className="text-accent">.</span>
        </span>
        <span className="text-center text-sm">
          Welcome back, nice to see you again!
        </span>
      </div>
      {!calculating ? (

        <>
          <div className="grid auto-cols-min grid-cols-2 gap-5">
            <div className="flex flex-col xl:flex-row items-center rounded-lg border-[#CF7831] border-2 bg-transparent p-4">
              <div className="flex  shrink-0 h-8 w-8 items-center justify-center rounded-full text-xl font-bold text-[#7DB8F2]">
                <span>{completedCourses}</span>
              </div>
              <span className="text-center font-semibold text-[#7DB8F2] xl:ml-2 xl:text-left ">
                Completed Courses
              </span>
            </div>
            <div className="flex flex-col xl:flex-row  items-center rounded-lg border-[#CF7831] border-2 bg-transparent p-4">
              <div className="flex shrink-0 h-8 w-8  items-center justify-center rounded-full text-xl font-bold text-[#7DB8F2]">
                <span>{coursesInProgress}</span>
              </div>
              <span className="text-center font-semibold text-[#7DB8F2] xl:ml-2 xl:text-left">
                Courses In Progress
              </span>
            </div>
          </div>

          <div className="flex flex-col overflow-hidden">
            <strong className="font-semibold  text-lg mb-2">
              Learning Path Progress
            </strong>
            <div
              className="flex flex-col justify-start transition-all duration-500 ease-in-out origin-top overflow-hidden"
              style={{ height: isSelfOpen ? "100%" : "0px" }}
            >
              {mainCourses.map(
                ({ nombre, porcentajeCompletado }, index) => {
                  return (
                    <Bar
                      key={nombre + index}
                      nombre={nombre}
                      porcentajeCompletado={porcentajeCompletado}
                    />
                  );
                }
              )}
            </div>
            <button
              onClick={() => {
                setIsSelfOpen(!isSelfOpen);
              }}
              className={"cursor-pointer"}
            >
              <ChevronDown
                className="w-full  h-8 transition-all duration-500 ease-in-out"
                style={{ transform: `rotateZ(${isSelfOpen ? 180 : 0}deg)` }}
              />
            </button>
          </div>

          <div className="flex flex-col overflow-hidden">
            <strong className="font-semibold text-lg mb-2">
              Self-Paced Courses Progress
            </strong>
            <div
              className="flex flex-col justify-start transition-all duration-500 ease-in-out origin-top overflow-hidden"
              style={{ height: isPacedOpen ? "100%" : "0px" }}
            >
              {secondaryCourses.map(
                ({ nombre, porcentajeCompletado }, index) => {
                  return (
                    <Bar
                      key={nombre + index}
                      nombre={nombre}
                      porcentajeCompletado={porcentajeCompletado}
                    />
                  );
                }
              )}
            </div>
            <button
              onClick={() => {
                setIsPacedOpen(!isPacedOpen);
              }}
              className={"cursor-pointer"}
            >
              <ChevronDown
                className="w-full h-8 transition-all duration-500 ease-in-out"
                style={{ transform: `rotateZ(${isPacedOpen ? 180 : 0}deg)` }}
              />
            </button>
          </div>

          <div className="flex flex-col gap-3 mb-4">
            <strong className="font-semibold text-lg">
              Practical sessions available this month{" "}
            </strong>
            <p className="font-medium mr-3 whitespace-nowrap">
              {`${appointmentsAvailable} out of 12`}
            </p>
          </div>
        </>

      ) : (
        <p className="w-full text-center">Loading...</p>
      )}
    </div>
  );
}
