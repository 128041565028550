import axiosInstance from "./axiosInstance";
import { API_KEY } from "./settings";

export const getAllCoursesAsync = async () => {
     const url = `Cursos/GetAll/${API_KEY}/`;
     const axios = axiosInstance();
     const res = await axios.get(url);
     const courses = await res.json();
     return courses;
};
