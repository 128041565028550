import React, { useContext, useEffect, useState } from "react";
import { CoursesContext } from "context/coursesContext";
import CourseListSection from "components/CoursesSection";
import {
  filterBySearch,
  filterByStatus,
} from "utils";
import { Typography } from "@mui/material";
import { UpgradePlanPage } from "components/UpgradePlanPage";


export default function CoursesPage({
  url,
  requiredPlanFeature,
  defaultSearch,
}) {
  const defaultStatus = defaultSearch ? "in progress" : "";
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [status, setStatus] = useState(defaultStatus);
  const { courses, userPlan } = useContext(CoursesContext);
  const [hasRequiredPlan, setHasRequiredPlan] = useState(false);

  useEffect(() => {
    console.log(requiredPlanFeature);
    console.log(userPlan.features);
    setHasRequiredPlan(userPlan?.features?.find((item) => {
      // si el plan del usuario no tiene este setting activo, no podra acceder a la funcionalidad
      return item.featureName === requiredPlanFeature && item.enabled;
    }))
  }, [userPlan]);

  useEffect(() => {
    setLoading(true);
    let filteredCourses = courses;
      if (status) {
        filteredCourses = filterByStatus(filteredCourses, status);
      }
    setCourse(filteredCourses);
    setLoading(false);
  }, [status, courses]);

  useEffect(() => {
    if (status !== "in progress") {
      setStatus(defaultStatus);
      setCourse(filterBySearch(courses, status));
    }
  }, [status, courses, defaultStatus]);

  return hasRequiredPlan ? (
    <>
      <section className="flex w-full flex-col gap-5 p-5 md:p-10 lg:px-14">
        {loading ? (
          <div>
            <h2 className="text-[#7DB8F2] text-[26px] font-[700] font-roboto ml-10">Loading data </h2>
          </div>
        ) : course.length > 0 ? (
          <div>
            {defaultSearch ? (
              <h2 className="text-[#7DB8F2] text-[26px] font-[700] font-roboto ml-10">
                Learning Path
              </h2>
            ) : null}
            <CourseListSection courses={course} url={"courses"} />
            {defaultSearch ? (
              <div>
                <h2 className="text-[#7DB8F2] text-[26px] font-[700] font-roboto ml-10">
                  Your Courses
                </h2>
                <CourseListSection courses={course} url={"courses-paced"} />
              </div>
            ) : null}
          </div>
        ) : (
          <Typography variant="h6" mt={5} color="#7DB8F2">
            No results, probably you have not completed progress.
          </Typography>
        )}
      </section>
    </>
  ) : (
    <UpgradePlanPage />
  );
}
