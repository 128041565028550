import { useContext, useState, useEffect } from "react"
import { Route, Switch } from "wouter"

import { SizeContext } from "context/SizeContext"

import { getAllCoursesAsync } from "services/getAllCoursesAsync"
import { CoursesContext } from "context/coursesContext"

import Header from "components/Header"
import SideBar from "components/SideBar"

import DashboardPage from "pages/Dashboard"
import CoursePage from "pages/Course"
import ProfilePage from "pages/Profile"
import StudentsPage from "pages/Students"
import { MyCommunityPage } from "pages/MyCommunity"
import MyTopicPage from "pages/MyTopic"
import ProfessorCoursesPage from "pages/ProfessorCourses"
import AssistancePage from "pages/Assistance"
import FollowUpPage from "pages/FollowUp/FollowUpPage"
import ProgressPage from "pages/Progress"
import JitsiMeetPage from "pages/JitsiMeet"
import CourseCommunityPage from "pages/CourseCommunity"
import MaterialCommunityPage from "pages/MaterialCommunity"
import { AddAsistancesPage } from "pages/AddAsistances"
import { BubblecommunityPage } from "pages/BubbleCommunity"
import CourseCommunityList from "pages/CourseCommunityList"
import ClassMaterialPage from "pages/ClassMaterial/ClassMaterialPage"
import MaterialProf from "pages/MaterialProf"

export const ProfessorView = () => {
    const user = JSON.parse(window.localStorage.getItem("loggedAppUser"))
    const { showBar } = useContext(SizeContext)
    const [courses, setCourses] = useState([])

    const getData = async () => {
        const courses = await getAllCoursesAsync()
        setCourses(courses)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <CoursesContext.Provider value={{ courses, profesor: true }}>
            <div
                className={`App size-full flex flex-col items-center ${showBar ? "md:ml-60" : "md:ml-12"
                    }`}
            >
                <SideBar />
                <Header user={user} />
                <main className='w-full h-full'>
                    <Switch>
                        <Route component={DashboardPage} path='/' />
                        <Route component={StudentsPage} path='/students' />
                        <Route component={ProfessorCoursesPage} path='/courses' />
                        <Route path='/course-community'>
                            {(params) => (
                                <CourseCommunityPage
                                    url={"course-community"}
                                    params={params}
                                />
                            )}
                        </Route>
                        {/* <Route path="/course-community/:courseId">
                                  {(params) => (
                                       <ModuleCommunityPage url="course-community" params={params} />
                                  )}
                             </Route> */}
                        <Route path='/course-community/:courseId'>
                            {(params) => (
                                <CoursePage url={"course-community"} params={params} />
                            )}
                        </Route>
                        <Route path='/courses-community-no/:courseId'>
                            {(params) => (
                                <CourseCommunityList
                                    url='courses-community-no'
                                    params={params}
                                />
                            )}
                        </Route>
                        <Route path='/courses-community-no/:courseId/module/:moduleId/material/:materialId'>
                            {(params) => (
                                <MaterialProf
                                    url={"courses-community-no"}
                                    params={params}
                                    community={true}
                                />
                            )}
                        </Route>
                        <Route path='/course-community/bubble/:courseId/:moduleId'>
                            {(params) => (
                                <BubblecommunityPage
                                    params={params}
                                    url={"course-community"}
                                />
                            )}
                        </Route>
                        <Route path='/course-community/:courseId/module/:moduleId/material/:materialId/:bubbleId'>
                            {(params) => (
                                <MaterialCommunityPage
                                    url={"course-community"}
                                    params={params}
                                />
                            )}
                        </Route>
                        <Route component={ClassMaterialPage} path='/class-material' />
                        <Route component={AssistancePage} path='/assistance' />
                        <Route
                            component={AddAsistancesPage}
                            path='/assistance/ingresar'
                        />
                        <Route component={FollowUpPage} path='/followup' />
                        <Route component={ProgressPage} path='/progress' />
                        <Route component={ProfilePage} path='/profile' />
                        <Route component={MyCommunityPage} path='/community' />
                        <Route component={MyTopicPage} path='/community/:topicId' />
                        <Route component={JitsiMeetPage} path='/JitsiMeet/:jitsiId' />
                    </Switch>
                </main>
            </div>
        </CoursesContext.Provider>
    )
}