import axiosInstance from "./axiosInstance";
import { USER_ID } from "./settings";

export const getStatisticsLevel = async () => {
     try {
          const url = `/Usuario/GetEstadisticasNivel/${USER_ID}`;
          const axios = axiosInstance();
          const res = await axios.get(url);
          if (!res.ok) throw new Error("Ha ocurrido un error");
          const stadistics = await res.json();
          return {
               ok: true,
               levelStatistics: stadistics,
          };
     } catch (error) {
          return {
               ok: false,
               errorMessage: error.response.data.message || "Ha ocurrido un error",
          };
     }
};
