import React, { useContext } from "react";
import { useLocation } from 'react-router-dom';

import { ReactComponent as IconStar } from "../../assets/star.svg";
import { useAccess } from "hooks/useAccess";
import { UIContext } from "context/UIContext";

const TopicCard = ({
  id,
  name,
  difficulty,
  description,
  position,
  enabled,
  appointmentsAvailable,
  courseID,
  openConfirmation,
  isSchedulingModuleOpen,
}) => {
  const n = 5;
  const starsArray = new Array(n);
  starsArray.fill(0);
  starsArray.fill(1, 0, difficulty);

  const { isBasic } = useAccess()
  const { openModal } = useContext(UIContext)
  const location = useLocation();
  const locationHash = location.hash ? location.hash.slice(1) : null;
  const topicHash = 'topic-'+id;
  return (
    <div id={topicHash} className={`flex flex-col p-4 rounded-3xl border-4  border-[#7DB8F2] bg-transparent text-[#7DB8F2] items-center justify-between text-center ${locationHash === topicHash ? 'bg-hl':''}`}>
      <strong className="text-xl font-bold mb-5 leading-5">{name}</strong>
      {/* <p className="text-sm font-light mr-2 mb-4">
        ({description})
      </p> */}
      <div className="flex flex-col items-center mb-6">
        <p className="text-lg tracking-wide font-bold mr-2">
          Difficulty level
        </p>
        <div className="flex">
          {starsArray.map((star, index) => {
            return (
              <IconStar
                key={index}
                className={`h-4 ${star === 1 ? "fill-[#CF7831]" : "stroke-[#CF7831] fill-transparent stroke-[10px]"
                  }`}
              />
            );
          })}
        </div>
      </div>
      <button
        className="p-2 pt-1 px-8 rounded-full bg-[#CF7831] hover:bg-blue-900 disabled:bg-gray-400 text-white font-semibold w-fit"
        onClick={() => {
          if (isBasic) return openModal()
          if (!isSchedulingModuleOpen) {
            openConfirmation(courseID, { id, name, description, position });
          }
        }}
        disabled={
          appointmentsAvailable <= 0
            ? true
            : isSchedulingModuleOpen
              ? true
              : !enabled

        }
      >
        Schedule
      </button>
    </div>
  );
};

export default TopicCard;
